import React from 'react';
import { node, string, object } from 'prop-types';
import classNames from 'classnames';

function Section({ type = null, className = null, style = null, children = null, testId = null }) {
  return (
    <section data-testid={type || testId} className={classNames(type, className)} style={style} type={type}>
      {children}
    </section>
  );
}

Section.propTypes = {
  children: node,
  className: string,
  type: string,
  style: object,
  testId: string,
};

export default Section;
